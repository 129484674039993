import Grid from '@mui/material/Grid';
import EventOrGroupCard from 'components/EventOrGroupCard';

function EventOrGroupList(props: { data: any }) {
  const { data } = props;

  return (
    <>
      {Boolean(data?.length) && (
        <Grid container rowSpacing={4} marginBottom="2em" marginTop="0.1em">
          {data.map((o: any, idx: number) => (
            <Grid item lg={4} md={6} xs={12} key={idx}>
              <EventOrGroupCard data={o} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default EventOrGroupList;
