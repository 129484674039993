import { Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import { SocialGroupOverview } from 'types';
import '../../styles/groupcard.scss';
import { GroupDescription } from './GroupInfo';


function GroupCard(props: SocialGroupOverview) {
  const { name, imageUrl, description, socialGroupId } = props;
 
  return (
    <>
      <CardImage
        imageUrl={socialGroupId === -1 ? imageUrl : GetImageUrl(imageUrl)}
        title={name}
        isEvent={false}
      />
      <CardContent>
        <GroupDescription description={description} />
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          left: '0',
          right: '0',
        }}
      > 
      <Button
            disabled = {true}
            color="primary"
            variant="outlined"
            sx={{
                width: '48%',
                minWidth: '40%',
                whiteSpace: 'nowrap',
                marginright: '0px',
                float: 'left',
            }}
        >
          Learn more
        </Button>
        <Button
            disabled = {true}
            variant =  'contained' 
            sx={{
                whiteSpace: 'nowrap',
                color: 'primary',
                width: '48%',
                marginLeft: '10px',
                float: 'right',
            }}
        >
            {'Join'}
        </Button>
        
      </Box>
    </>
  );
}

export default GroupCard;
