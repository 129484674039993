import { useAuth0 } from '@auth0/auth0-react';
import {
  AccessTime,
  CalendarMonthRounded,
  PlaceRounded,
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import {
  Button,
  ButtonGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import getAttendeeList from 'api/getAttendeeList';
import getWaitingList from 'api/getWaitingList';
import AlertBox from 'components/AlertBox/AlertBox';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import RemoveAttendeesDialog from 'components/RemoveAttendeesDialog';

import RemoveAttendeesDropdown from 'components/RemoveAttendeesDropdown';
import { isAuthorizedEvent } from 'helpers/AuthorizedPage';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { AttendingUser } from 'types';

function InfoBar(props: {
  address: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  isPublished: boolean;
  creatorEmail: string;
  reloadData: any;
  maxCapacity?: number;
  attendances: number;
  deadlineAttendance: string;
  startAttendance: string;
  waitingList: number;
  waitingListPosition: number;
  waitingListStatus: boolean;
  users: any;
}) {
  const {
    address,
    startTime,
    endTime,
    isPublished,
    reloadData,
    maxCapacity,
    attendances,
    creatorEmail,
    waitingList,
    waitingListPosition,
    waitingListStatus,
    users,
  } = props;
  const handleRemoveAttendees = () => {
    // Reload data after attendees are removed

    props.reloadData();
  };
  const TextItemAlert = (
    text: string,
    text2: string,
    icon: any,
    text3: string
  ) => (
    <ListItem>
      <ListItemIcon sx={{ color: 'primary.main' }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      <ListItemText sx={{ color: 'red' }} primary={text2} />
      {waitingListStatus ? (
        <ListItemText sx={{ color: 'red' }} primary={'pos: ' + text3} />
      ) : (
        <></>
      )}
    </ListItem>
  );
  const TextItem = (text: string, icon: any) => (
    <ListItem>
      <ListItemIcon sx={{ color: 'primary.main' }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
  const { email, isAuthenticated, authorizedEvents } = useUser();
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const parsedEventId = eventId ? parseInt(eventId) : undefined;
  // const {
  //   data: users,
  //   isLoading: isLoadingMembers,
  //   reload: reloadMembers,
  // } = useHttpGet<MemberCardInterface[]>(`/events/${eventId}/users`);

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [alertText, setAlertText] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const fetchAttendeeList = async () => {
    if (eventId && isAuthenticated) {
      await getAttendeeList(parseInt(eventId), await getAccessTokenSilently());
    }
  };

  const fetchWaitingList = async () => {
    if (eventId && isAuthenticated) {
      await getWaitingList(parseInt(eventId), await getAccessTokenSilently());
    }
  };

  const {
    httpDelete: httpDeleteEvent,
    isLoading: isLoadingDelete,
    error,
  } = useHttpDelete({});
  const handleDeleteEvent = () => {
    httpDeleteEvent(`/events/${eventId}`);
    if (!isLoadingDelete && !error) {
      setOpenConfirmationBox(false);
      setAlertText('Activity has been deleted');
      setOpenAlertBox(true);
    }
  };

  const { send: toggle } = useHttpWithData<any>({
    url: `/events/updateEventPublished`,
    method: 'put',
  });

  const handleTogglePublish = () => {
    toggle({
      eventId: eventId,
      isPublished: isPublished ? false : true,
    }).then(() => {
      reloadData();
    });
  };

  const handleAlertBox = () => {
    setOpenAlertBox(false);
    setAlertText('');
    navigate('/events');
  };
  const [openRemoveAttendeesDialog, setOpenRemoveAttendeesDialog] =
    useState(false);

  const handleOpenRemoveAttendeesDialog = () => {
    setOpenRemoveAttendeesDialog(true);
  };

  const handleCloseRemoveAttendeesDialog = () => {
    setOpenRemoveAttendeesDialog(false);
  };
  const { data: emails } = useHttpGet<string[]>(`/events/${eventId}/emails`);

  const showCorrectMembers = () => {
    if (waitingList > 0) {
      return TextItemAlert(
        `${attendances}/${maxCapacity}`,
        `(${waitingList})`,
        <GroupIcon />,
        `${waitingListPosition}`
      );
    } else {
      return (
        <ListItem>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            {<GroupIcon />}
          </ListItemIcon>
          <ListItemText primary={`${attendances}/${maxCapacity}`} />
        </ListItem>
      );
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          borderRadius: '10px',
          marginTop: '5px',
        }}
        sx={{
          backgroundColor: 'info.main',
          color: '#000',
          fontWeight: 'bold',
        }}
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={4}>
          {TextItem(address, <PlaceRounded />)}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          {TextItem(
            startTime.format('dddd Do of MMMM'),
            <CalendarMonthRounded />
          )}
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          {TextItem(
            startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm'),
            <AccessTime />
          )}
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          {maxCapacity
            ? showCorrectMembers()
            : TextItem(`${attendances}`, <GroupIcon />)}
        </Grid>
      </Grid>

      {(isAuthorizedEvent(eventId ?? '', authorizedEvents) ||
        email === creatorEmail) && (
        <>
          {!isMobile && (
            <ButtonGroup
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              sx={{
                display: 'flex',
                marginTop: '1em',
              }}
            >
              <Button
                sx={{
                  fontSize: '0.9rem',
                }}
                onClick={() => navigate(`/management/events/edit/${eventId}`)}
              >
                Edit Activity
              </Button>
              <Button
                sx={{
                  fontSize: '0.9rem',
                }}
                onClick={(e) => {
                  const emailsWithSemiColon = emails?.join(';');
                  window.location.href = `mailto:${emailsWithSemiColon}`;
                  e.preventDefault();
                }}
              >
                Email Attendees
              </Button>
              <Button
                sx={{
                  fontSize: '0.9rem',
                }}
                onClick={fetchAttendeeList}
              >
                Download Attendee List
              </Button>
              {waitingList > 0 && (
                <Button
                  sx={{
                    fontSize: '0.9rem',
                  }}
                  onClick={fetchWaitingList}
                >
                  Download Waiting List
                </Button>
              )}
              <Button
                sx={{
                  fontSize: '0.9rem',
                }}
                onClick={handleOpenRemoveAttendeesDialog}
              >
                Manage Attendees
              </Button>
              <Button
                sx={{
                  fontSize: '0.9rem',
                }}
                onClick={handleTogglePublish}
              >
                {isPublished ? 'Hide Activity' : 'Publish Activity'}
              </Button>

              <Button
                disabled={isPublished}
                onClick={() => setOpenConfirmationBox(true)}
                sx={{
                  fontSize: '0.9rem',
                  color: isPublished ? 'grey' : '#fff',
                  backgroundColor: isPublished ? '#eee' : '#f44336',
                  '&:hover': {
                    backgroundColor: isPublished ? '#eee' : '#f44336',
                  },
                  boxSizing: 'border-box',
                }}
              >
                Delete Activity
              </Button>
            </ButtonGroup>
          )}
          {isMobile && (
            <ButtonGroup
              orientation="vertical"
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              sx={{
                display: { xs: 'flex', sm: 'none' },
                marginTop: '1em',
              }}
            >
              <Button
                onClick={() => navigate(`/management/events/edit/${eventId}`)}
              >
                Edit Activity
              </Button>
              <Button
                onClick={(e) => {
                  const emailsWithSemiColon = emails?.join(';');
                  window.location.href = `mailto:${emailsWithSemiColon}`;
                  e.preventDefault();
                }}
              >
                Email Attendees
              </Button>
              <Button onClick={fetchAttendeeList}>
                Download Attendee List
              </Button>
              {waitingList > 0 && (
                <Button onClick={fetchWaitingList}>
                  Download Waiting List
                </Button>
              )}
              <Button onClick={handleOpenRemoveAttendeesDialog}>
                Manage Attendees
              </Button>
              <Button onClick={handleTogglePublish}>
                {isPublished ? 'Hide Activity' : 'Publish Activity'}
              </Button>

              <Button
                disabled={isPublished}
                onClick={() => setOpenConfirmationBox(true)}
                sx={{
                  color: isPublished ? 'grey' : '#fff',
                  backgroundColor: isPublished ? '#eee' : '#f44336',
                  '&:hover': {
                    backgroundColor: isPublished ? '#eee' : '#f44336',
                  },
                }}
              >
                Delete Activity
              </Button>
            </ButtonGroup>
          )}
        </>
      )}
      <RemoveAttendeesDialog
        open={openRemoveAttendeesDialog}
        onClose={handleCloseRemoveAttendeesDialog}
        onRemoveAttendees={handleRemoveAttendees}
        allAttendees={users ?? []}
        eventId={parsedEventId ?? 0}
      />
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Are you sure you want to cancel this activity?`}
        content={'The activity will be removed permanently'}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleDeleteEvent();
        }}
      />
      <AlertBox
        text={alertText}
        handleClose={handleAlertBox}
        open={openAlertBox}
      />
    </>
  );
}

export default InfoBar;
