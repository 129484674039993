import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import LoadingIcon from 'components/LoadingIcon';
import Modal from './OverlayModal';
import ImageStepperComp from './ImageStepperComp';
import { isMobile } from 'react-device-detect';
import './index.scss';
import { Button } from '@mui/material';

export default function ImageStepper(props: { carouselData: any }) {
  const { carouselData } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0); // State to track clicked image index
  const [showAllImages, setShowAllImages] = useState(false);

  useEffect(() => {
    if (!carouselData) {
    } else {
      <LoadingIcon />;
    }
  }, [carouselData]);

  const handleImageClick = (index: number) => {
    setActiveSlide(index);
    setClickedImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleShowAllImages = () => {
    setShowAllImages(true);
    setActiveSlide(0);
    setShowModal(true);
  };

  return (
    <div className="image-stepper-container">
      <div className="image-list">
        {carouselData && (
          <ImageList
            sx={
              isMobile
                ? {
                    width: 'auto',
                    height: 450,
                    overflowX: 'hidden',
                    borderRadius: 3,
                  }
                : { width: 'auto' , height: 450, borderRadius: 3 }
            }
            variant="quilted"
            cols={
              isMobile ? 1 : carouselData.length >= 5 ? 4 : carouselData.length
            }
            gap={4}
          >
            {carouselData.slice(0, 5).map((imageUrl: string, index: number) => (
              <ImageListItem
                style={
                  isMobile
                    ? { display: 'flex', flexDirection: 'row' }
                    : { overflow: 'hidden' }
                }
                key={imageUrl}
                onClick={() => handleImageClick(index)}
                className="ImageListItem"
                rows={
                  carouselData.length >= 5 ? (index === 0 ? 2 : 1) : undefined
                }
                cols={
                  isMobile
                    ? carouselData.length >= 5
                      ? index === 0
                        ? 1
                        : 1
                      : undefined
                    : carouselData.length >= 5
                    ? index === 0
                      ? 2
                      : 1
                    : undefined
                }
              >
                <img
                  src={imageUrl}
                  alt={`img ${index}`}
                  loading="lazy"
                  className="HoverImage"
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
      {showModal && (
        <Modal onClose={closeModal}>
          <ImageStepperComp
            carouselData={carouselData}
            initialSlide={clickedImageIndex}
          />
        </Modal>
      )}
      {carouselData && (
        <Button
          variant="contained"
          style={{
            position: 'relative',
            float: 'right',
            bottom: '4.5rem',
            right: '2rem',
          }}
          sx={{
            backgroundColor: 'primary.main',
          }}
          onClick={handleShowAllImages}
        >
          Show all {carouselData.length} images
        </Button>
      )}
    </div>
  );
}
