import { Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import { useNavigate } from 'react-router';
import { SocialGroupOverview } from 'types';
import '../../styles/groupcard.scss';
import { GroupDescription } from './GroupInfo';
import JoinGroupButton from 'pages/GroupPage/JoinGroupButton';
import useUser from 'hooks/useUser';
import useHttpGet from 'hooks/useHttpGet';
import { MemberCardInterface } from 'components/MemberOverview/MemberCard';
import { useEffect } from 'react';
import LoadingIcon from 'components/LoadingIcon';
import { isAuthorizedGroup } from 'helpers/AuthorizedPage';

function GroupCard(props: SocialGroupOverview) {
  const { name, imageUrl, description, socialGroupId } = props;
  const { isAuthenticated, userId, authorizedGroups } = useUser();
  const navigate = useNavigate();
  const {
    data: group,
    isLoading: loadingGroup,
    error,
    reload: reloadGroup,
  } = useHttpGet<any>(`/social-groups/${socialGroupId}/overview`);

  const { isLoading: loadMember, reload: reloadIsMember } = useHttpGet<boolean>(
    `/memberships/${userId}/${socialGroupId}/member`
  );

  const { reload: reloadMembers } = useHttpGet<MemberCardInterface[]>(
    `/social-groups/${socialGroupId}/users`
  );

  const reloadData = () => {
    reloadGroup();
    reloadIsMember();
    reloadMembers();
  };

  useEffect(() => {
    if (error) navigate('/page-not-found');
  }, [error]);

  useEffect(() => {
    if (group === undefined) return;
    if (!authorizedGroups.length && isAuthenticated) return;
    if (
      isAuthorizedGroup(socialGroupId ?? '', authorizedGroups) ||
      group?.isPublished
    )
      return;
    navigate('/');
  }, [group, authorizedGroups]);

  if (loadingGroup || loadMember) {
    return <LoadingIcon />;
  }

  return (
    <>
      <div onClick={() => navigate(`/groups/${socialGroupId}`)}>
        <CardImage
          imageUrl={socialGroupId === -1 ? imageUrl : GetImageUrl(imageUrl)}
          title={name}
          isEvent={false}
        />
        <CardContent>
          <GroupDescription description={description} />
        </CardContent>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          left: '0',
          right: '0',
        }}
      >
        <Button
          disableElevation
          disabled={socialGroupId === -1 ? true : false}
          color="primary"
          variant="outlined"
          onClick={() => navigate(`/groups/${socialGroupId}`)}
          sx={{
            width: isAuthenticated ? '48%' : '100%',
            minWidth: '40%',
            whiteSpace: 'nowrap',
            marginLeft: '10px',
            marginright: !isAuthenticated ? '10px' : '0px',
            float: 'left',
          }}
        >
          Learn more
        </Button>
        {isAuthenticated && (
          <JoinGroupButton
            socialGroupId={socialGroupId}
            displayText={false}
            reloadData={reloadData}
          />
        )}
      </Box>
    </>
  );
}

export default GroupCard;
