import { useAuth0 } from '@auth0/auth0-react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { currentLocation } from 'atoms';
import { LocationMenu } from './LocationMenu';
import { UserLogin } from './UserLoginComponent';
import './index.scss';
import PushNotifications from 'components/PushNotifications';

const OpenMenuButton = (openMenu: () => void) => (
  <IconButton onClick={openMenu}>
    <MenuIcon htmlColor="black" />
  </IconButton>
);

const Navbar = (props: { openMenu: () => void }) => {
  const navigate = useNavigate();

  const pathname = useLocation();

  const [curLocation] = useRecoilState(currentLocation);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { location: userLocation, isAuthenticated, isLoading } = useUser();
  const { loginWithRedirect } = useAuth0();

  const path = useLocation();

  function isCurrentPath(currentPath: string) {
    return path.pathname.toLowerCase() === currentPath.toLowerCase();
  }

  function includesCurrentPath(currentPath: string) {
    return path.pathname.toLowerCase().includes(currentPath.toLowerCase());
  }

  return (
    <>
      <AppBar
        component="nav"
        sx={{
          background: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + +!isMobile,
        }}
      >
        <Container maxWidth={'xl'} sx={{ my: 'auto', color: 'black' }}>
          <Toolbar disableGutters style={{ minHeight: 0 }}>
            {isMobile && OpenMenuButton(props.openMenu)}
            {isMobile && (
              <Box
                onClick={() => navigate(`/locations`)}
                component="img"
                sx={{
                  maxWidth: '150px',
                  maxHeight: '50px',
                  marginLeft: '16px',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                alt="Logo"
                src={`/CapSocialLogo_${curLocation}.svg`}
              />
            )}
            {isMobile && (
              <Box
                sx={{ flexGrow: 100, display: { xs: 'flex', md: 'flex' } }}
              />
            )}

            {!isMobile && (
              <Box
                onClick={() => navigate(`/locations`)}
                component="img"
                sx={{
                  maxWidth: '150px',
                  maxHeight: '50px',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                alt="Logo"
                src={`/CapSocialLogo_${curLocation}.svg`}
              />
            )}
            {isMobile && isAuthenticated && <PushNotifications />}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
            {!isMobile && (
              <Button
                disableElevation
                className={'nav-button'}
                onClick={() => navigate(`/locations/${userLocation}`)}
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                  marginRight: 2,
                }}
                sx={{
                  color: 'primary.main',
                  borderBottom: isCurrentPath(`/locations/${userLocation}`)
                    ? 5
                    : 0,
                }}
              >
                <Typography variant="h5" color={'black'}>
                  Home
                </Typography>
              </Button>
            )}
            {!isMobile && (
              <LocationMenu
                isCurrentPath={
                  includesCurrentPath(`locations`) &&
                  !isCurrentPath(`/locations/${userLocation}`)
                }
              />
            )}
            {!isMobile && (
              <Button
                disableElevation
                className={'nav-button'}
                onClick={() => navigate(`/groups?city=${userLocation}`)}
                style={{ backgroundColor: 'transparent', borderRadius: 0 }}
                sx={{
                  color: 'primary.main',
                  borderBottom: includesCurrentPath(`groups`) ? 5 : 0,
                }}
              >
                <Typography variant="h5" color={'black'}>
                  Groups
                </Typography>
              </Button>
            )}
            {!isMobile && (
              <Button
                disableElevation
                className={'nav-button'}
                onClick={() => navigate(`/events?city=${userLocation}`)}
                style={{ backgroundColor: 'transparent', borderRadius: 0 }}
                sx={{
                  color: 'primary.main',
                  borderBottom: includesCurrentPath(`events`) ? 5 : 0,
                }}
              >
                <Typography variant="h5" color={'black'}>
                  Activities
                </Typography>
              </Button>
            )}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
            {!isLoading && !isMobile && (
              <>
                {!isAuthenticated && (
                  <Button
                    disableElevation
                    color="inherit"
                    onClick={() => loginWithRedirect()}
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: 0,
                    }}
                  >
                    Log in
                  </Button>
                )}
                {isAuthenticated && !isMobile && <PushNotifications />}
                {isAuthenticated && <UserLogin />}
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Navbar;
