import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import AlertBox from 'components/AlertBox/AlertBox';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import { isAuthorizedGroup } from 'helpers/AuthorizedPage';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';

function GroupButtons(props: {
  isMember: any;
  authorizedGroups: any;
  isAdmin: any;
  reloadData: any;
  isPublished: any;
}) {
  const { userId, authorizedGroups } = useUser();
  const { reloadData, isPublished } = props;
  const { id: socialGroupId } = useParams();
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [alertText, setAlertText] = useState('');

  const handleToCloseError = () => setOpenError(false);

  const { data: isLead } = useHttpGet<number[]>(
    `/memberships/${userId}/${socialGroupId}/is-lead`
  );

  const { send: toggle } = useHttpWithData<any>({
    url: `/social-groups/toggleSocialGroupActive`,
    method: 'put',
  });

  const handleTogglePublish = () => {
    toggle({
      socialGroupId: socialGroupId,
      isPublished: isPublished ? false : true,
    }).then(() => {
      reloadData();
    });
  };

  const {
    httpDelete: httpDeleteGroup,
    isLoading: isLoadingDelete,
    error,
  } = useHttpDelete({});

  function handleDeleteGroup() {
    httpDeleteGroup(`/social-groups/${socialGroupId}`);
    if (!isLoadingDelete && !error) {
      setOpenConfirmationBox(false);
      setAlertText('Group has been deleted');
      setOpenAlertBox(true);
    }
    setOpenConfirmationBox(false);
  }

  const handleAlertBox = () => {
    setOpenAlertBox(false);
    setAlertText('');
    navigate('/groups');
  };

  const { data: emails } = useHttpGet<string[]>(
    `/social-groups/${socialGroupId}/emails`
  );

  function errorDialog(open: boolean) {
    return (
      <Dialog open={open} onClose={() => setOpenError(false)}>
        <Grid container justifyContent="center">
          <DialogTitle>An error occured</DialogTitle>
        </Grid>
        <DialogContent>
          <Grid container justifyContent={'center'}>
            <DialogContentText padding={0}>
              Make sure you are logged in
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-evenly"
            paddingBottom={'1rem'}
          >
            <Button
              disableElevation
              variant="contained"
              sx={{ backgroundColor: '#BBB', color: 'white' }}
              onClick={handleToCloseError}
              color="primary"
              autoFocus
            >
              close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {(isAuthorizedGroup(socialGroupId ?? '', authorizedGroups) || isLead) && (
        <Grid container justifyContent={'center'} direction={'row'}>
          {' '}
          {!isMobile && (
            <ButtonGroup variant="text">
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                }}
                onClick={() =>
                  navigate(`/management/groups/edit/${socialGroupId}`)
                }
              >
                Edit Group
              </Button>

              <Button
                disableElevation
                variant="contained"
                onClick={(e) => {
                  window.location.href = `mailto:${emails?.join(';')}`;
                  e.preventDefault();
                }}
                sx={{
                  backgroundColor: 'primary.main',
                }}
              >
                Email Members
              </Button>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                  px: 2,
                }}
                onClick={() => {
                  handleTogglePublish();
                }}
              >
                {isPublished ? 'Hide Group' : 'Publish Group'}
              </Button>
              <Button
                disabled={isPublished}
                disableElevation
                sx={{
                  color: isPublished ? 'grey' : '#fff',
                  backgroundColor: isPublished ? '#eee' : '#f44336',
                  '&:hover': {
                    backgroundColor: isPublished ? '#eee' : '#f44336',
                  },
                }}
                onClick={() => setOpenConfirmationBox(true)}
              >
                Delete Group
              </Button>
            </ButtonGroup>
          )}
          {isMobile && (
            <ButtonGroup variant="text" orientation="vertical" fullWidth>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                }}
                onClick={() =>
                  navigate(`/management/groups/edit/${socialGroupId}`)
                }
              >
                Edit Group
              </Button>

              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                }}
                onClick={() =>
                  navigate(`/management/groups/edit/${socialGroupId}`)
                }
              >
                Manage Images
              </Button>

              <Button
                disableElevation
                variant="contained"
                onClick={(e) => {
                  window.location.href = `mailto:${emails?.join(';')}`;
                  e.preventDefault();
                }}
                sx={{
                  backgroundColor: 'primary.main',
                }}
              >
                Email Members
              </Button>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                  px: 2,
                }}
                onClick={() => {
                  handleTogglePublish();
                }}
              >
                {isPublished ? 'Hide Group' : 'Publish Group'}
              </Button>
              <Button
                disabled={isPublished}
                disableElevation
                sx={{
                  color: isPublished ? 'grey' : '#fff',
                  backgroundColor: isPublished ? '#eee' : '#f44336',
                  '&:hover': {
                    backgroundColor: isPublished ? '#eee' : '#f44336',
                  },
                }}
                onClick={() => setOpenConfirmationBox(true)}
              >
                Delete Group
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      )}
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Are you sure you want to delete this group?`}
        content={'The group will be permanently deleted'}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleDeleteGroup();
        }}
      />

      <AlertBox
        open={openAlertBox}
        text={alertText}
        handleClose={handleAlertBox}
      />

      <>{errorDialog(openError)}</>
    </>
  );
}

export default GroupButtons;
