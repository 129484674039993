import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

export default function ConfirmationBox(props: {
  open: boolean;
  title: string;
  content: string;
  handleClose: () => void;
  handleResponse: (response: boolean) => void;
}) {
  const { open, title, content, handleClose, handleResponse } = props;

  return (
    <Dialog open={open}>
      {' '}
      {/*onClose={() => setOpen(false)}*/}
      <Grid container justifyContent="center">
        <DialogTitle>{title}</DialogTitle>
      </Grid>
      <DialogContent>
        <Grid container justifyContent={'center'}>
          <DialogContentText padding={0}>{content}</DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-evenly"
          paddingBottom={'1rem'}
        >
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => {
              handleResponse(true);
              handleClose();
            }}
            sx={{
              color: 'white',
            }}
          >
            {'Yes'}
          </Button>

          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              color: 'white',
            }}
            onClick={() => {
              handleResponse(false);
              handleClose();
            }}
            autoFocus
          >
            {'No'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
