import { Button, Typography } from '@mui/material';
import { isAuthorizedGroup } from 'helpers/AuthorizedPage';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

// REDUNDANT
interface Membership {
  appUserId: string;
  socialGroupId: number;
  roleId?: number;
}

function JoinEventButton(props: any) {
  //displaytext is a bool that refers to the (You are a member) text that shows on the group page
  const { socialGroupId, displayText, reloadData } = props;
  const { userId, isAuthenticated, setCurrentUser, authorizedGroups } =
    useUser();

  const { data: userCoreGroups } = useHttpGet<number[]>(
    `/users/${userId}/social-groups/core-member`
  );
  const navigator = useNavigate();

  const { send: joinGroup } = useHttpWithData<Membership>({
    url: '/memberships',
    method: 'post',
  });
  const { httpDelete: leaveGroup } = useHttpDelete<Membership>({});
  const {
    data: group,
    isLoading: loadingGroup,
    error,
  } = useHttpGet<any>(`/social-groups/${socialGroupId}/overview`);

  const { data: isMember, isLoading: loadMember } = useHttpGet<boolean>(
    `/memberships/${userId}/${socialGroupId}/member`
  );

  useEffect(() => {
    if (group === undefined) return;
    if (!authorizedGroups.length && isAuthenticated) return;
    if (
      isAuthorizedGroup(socialGroupId ?? '', authorizedGroups) ||
      group?.isPublished
    )
      return;
    navigator('/');
  }, [group, authorizedGroups]);

  useEffect(() => {
    if (error) navigator('/page-not-found');
  }, [error]);

  const handleGroupMembership = () => {
    if (!isMember) {
      joinGroup({
        appUserId: userId,
        socialGroupId: parseInt(socialGroupId ?? ''),
      }).then(() => {
        setCurrentUser((prev) => ({
          ...prev,
          memberships: [...prev.memberships, parseInt(socialGroupId ?? '')],
        }));
        reloadData();
      });
    } else {
      leaveGroup('/memberships', {
        appUserId: userId,
        socialGroupId: parseInt(socialGroupId ?? ''),
      }).then(() => {
        setCurrentUser((prev) => ({
          ...prev,
          memberships: prev.memberships.filter(
            (el) => el !== parseInt(socialGroupId ?? '')
          ),
        }));
        reloadData();
      });
    }
  };

  const disableLeave = isAuthorizedGroup(
    socialGroupId ?? '',
    userCoreGroups ?? []
  );

  return (
    <>
      <Button
        disableElevation
        disabled={disableLeave}
        variant={isMember ? 'outlined' : 'contained'}
        onClick={handleGroupMembership}
        sx={{
          margin: isMobile ? '0' : '0',
          whiteSpace: 'nowrap',
          color: 'primary',
          width: '48%',
          marginLeft: !displayText ? '10px' : '0',
          float: isMobile && displayText ? '' : 'right',
        }}
      >
        {isMember ? 'Leave Group' : 'Join'}
      </Button>
    </>
  );
}
export default JoinEventButton;
