import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box } from '@mui/material';
import './index.scss';

function CardLogo(props: { isEvent: boolean }) {
  const { isEvent } = props;
  return (
    <Box className="logo-background" sx={{ backgroundColor: 'info.dark' }}>
      {isEvent ? (
        <CelebrationOutlinedIcon sx={{ fontSize: '30px', color: 'white' }} />
      ) : (
        <PeopleOutlineIcon sx={{ fontSize: '30px', color: 'white' }} />
      )}
    </Box>
  );
}

export default CardLogo;
