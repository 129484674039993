import { CardMedia, Typography } from '@mui/material';
import CardLogo from './CardLogo';
import './index.scss';

function CardImage(props: {
  imageUrl: string;
  title: string;
  year?: string;
  isEvent: boolean;
}) {
  const { imageUrl, title, year, isEvent } = props;
  const imageHeight = '181';

  return (
    <div className="image-container">
      <CardLogo isEvent={isEvent} />
      <CardMedia
        className="image"
        sx={{ objectFit: 'cover' }}
        component="img"
        image={imageUrl}
        height={imageHeight}
        src="picture"
      ></CardMedia>
      <div className="eventcard-fader">
        <Typography className="event-name" fontSize={22}>
          {year && (
            <>
              <span className="year-color"> {year} </span> <br />
            </>
          )}
          {title}
        </Typography>
      </div>
    </div>
  );
}

export default CardImage;
