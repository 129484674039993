import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from 'api/axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';

function useHttpDelete<TPayload = undefined>(config?: AxiosRequestConfig) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const httpDelete = async (url: string, payload?: TPayload) => {
    try {
      setIsLoading(true);
      const accessToken = isAuthenticated && (await getAccessTokenSilently());
      await axiosInstance.delete(url, {
        ...config,
        data: payload,
        headers: { ...config?.headers, Authorization: `Bearer ${accessToken}` },
      });
      setError(undefined);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { error, isLoading, httpDelete };
}

export default useHttpDelete;
