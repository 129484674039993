import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import RemoveAttendeesDropdown from 'components/RemoveAttendeesDropdown';
import { AttendingUser } from 'types';
import { isMobile } from 'react-device-detect';

interface RemoveAttendeesDialogProps {
  open: boolean;
  onClose: () => void;
  onRemoveAttendees: () => void;
  allAttendees: AttendingUser[];
  eventId: number;
}

const RemoveAttendeesDialog: React.FC<RemoveAttendeesDialogProps> = ({
  open,
  onClose,
  onRemoveAttendees,
  allAttendees,
  eventId,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Manage Attendees
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: isMobile ? 'hidden' : 'auto',
        }}
      >
        <RemoveAttendeesDropdown
          allAttendees={allAttendees}
          eventId={eventId}
          onRemoveAttendees={onRemoveAttendees}
        />
      </DialogContent>
    </Dialog>
  );
};

export default RemoveAttendeesDialog;
