import React, { useState } from 'react';
import { AttendingUser } from 'types';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import useHttpWithData from 'hooks/useHttpWithData';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';

interface RemoveAttendeesDropdownProps {
  allAttendees: AttendingUser[];
  eventId: number;
  onRemoveAttendees: () => void;
}

const RemoveAttendeesDropdown: React.FC<RemoveAttendeesDropdownProps> = ({
  allAttendees = [],
  eventId,
  onRemoveAttendees,
}) => {
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const { send: removeAttendees, isLoading: isLoadingRemove } =
    useHttpWithData<any>({
      url: '/event-attendances/remove',
      method: 'put',
    });

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedAttendees(event.target.value as string[]);
  };

  const handleRemoveAttendees = () => {
    if (selectedAttendees.length === 0) {
      console.log('No attendees selected for removal.');
      return;
    }

    const attendeesToRemove = selectedAttendees.map((attendeeId) => ({
      AppUserId: attendeeId.toString(),
      EventId: eventId,
    }));

    removeAttendees({
      eventAttendances: attendeesToRemove,
    })
      .then(() => {
        console.log('Attendees removed successfully:', attendeesToRemove);
        onRemoveAttendees();
        setSelectedAttendees([]);
      })
      .catch((error) => {
        console.error('Error removing attendees:', error);
      });
  };

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormControl sx={{ width: 300 }} variant="standard">
        <InputLabel id="remove-attendees-label">Select Attendees</InputLabel>
        <Select
          labelId="remove-attendees-label"
          id="remove-attendees"
          multiple
          value={selectedAttendees}
          onChange={handleChange}
        >
          {allAttendees.map((attendee) => (
            <MenuItem key={attendee.appUserId} value={attendee.appUserId}>
              {attendee.fullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenConfirmationBox(true)}
        disabled={isLoadingRemove}
        disableElevation
        sx={{ height: '3rem', marginLeft: '0.313rem', marginTop: '0.5rem' }}
      >
        Remove Selected Attendees{' '}
        <PersonRemoveIcon sx={{ marginLeft: '0.5rem' }} />
      </Button>
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Remove Selected Attendees?`}
        content={''}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleRemoveAttendees();
        }}
      />
    </div>
  );
};

export default RemoveAttendeesDropdown;
