import { Button, Grid, Typography } from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import LoadingIcon from 'components/LoadingIcon';
import UserSearch from 'components/UserSearch/UserSearch';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserBase, UserWithImage } from 'types';

export default function DeleteUserPage() {
  const [user, setUser] = useState<UserBase | null>(null);
  const [displayText, setDisplayText] = useState<boolean>(false);
  const [, setSearchParams] = useSearchParams();

  const { httpDelete, isLoading: isLoadingDelete, error } = useHttpDelete({});
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

  const { data: users } = useHttpGet<UserWithImage[]>('/users');

  async function deleteUser() {
    if (!user) return;
    await httpDelete(`/users/${user.appUserId}`).then(() => {
      setDisplayText(true);
      setSearchParams({ delete: user.appUserId });
    });
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginY={10}
        gap={6}
        columns={{ xs: 4, md: 12 }}
      >
        <Grid item>
          <Typography variant="h3">Delete User</Typography>
        </Grid>
        <Grid item style={{ width: '15em' }}>
          <UserSearch
            setUser={(user) => {
              setUser(user);
              setDisplayText(false);
            }}
            users={users}
          />
        </Grid>
        <Grid item>
          <Button
            disableElevation
            disabled={!user?.appUserId}
            color="primary"
            onClick={() => setOpenConfirmationBox(true)}
            variant="contained"
            sx={{
              backgroundColor: 'red',
              color: '#FFF',
            }}
          >
            Delete User
          </Button>
        </Grid>
        {isLoadingDelete && (
          <Grid item>
            <LoadingIcon />
          </Grid>
        )}
        {!error && displayText && (
          <Grid item>
            <Typography color="red">{user?.fullName} deleted</Typography>
          </Grid>
        )}
      </Grid>
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Are you sure that you want to delete ${user?.fullName}?`}
        content={'The user will be permanently deleted'}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) deleteUser();
        }}
      />
    </>
  );
}
