import { Box, Button, CircularProgress, Grid } from '@mui/material';
import AlertBox from 'components/AlertBox/AlertBox';
import ConfirmationBoxNew from 'components/ConfirmationBox/ConfirmationBox';
import LoadingIcon from 'components/LoadingIcon';
import useHttpWithData from 'hooks/useHttpWithData';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

interface EventAttendance {
  appUserId: string;
  eventId: string;
}

function JoinEventButton(props: {
  eventId: number;
  userId: string;
  reloadAttendance: any;
  isAttending: any;
  maxCapacity: any;
  members: any;
  isWaiting: any;
  deadlineAttendance: any;
  startAttendance: any;
  isCard: boolean;
}) {
  const {
    userId,
    reloadAttendance,
    isAttending,
    maxCapacity,
    members,
    isWaiting,
    deadlineAttendance,
    startAttendance,
    eventId,
    isCard,
  } = props;

  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { send: registerJoinAttendance, isLoading: isLoadingJoin } =
    useHttpWithData<EventAttendance>({
      url: '/event-attendances/join',
      method: 'put',
    });
  const { send: registerLeaveAttendance, isLoading: isLoadingLeave } =
    useHttpWithData<EventAttendance>({
      url: '/event-attendances/leave',
      method: 'put',
    });

  const { send: registerJoinWaitingList, isLoading: isLoadingJoinWaitingList } =
    useHttpWithData<EventAttendance>({
      url: '/event-waitinglist/add',
      method: 'post',
    });

  const { send: leaveJoinWaitingList, isLoading: isLoadingLeaveWaitingList } =
    useHttpWithData<EventAttendance>({
      url: '/event-waitinglist/delete',
      method: 'post',
    });

  const handleregisterJoinWaitingList = () => {
    registerJoinWaitingList({
      appUserId: userId,
      eventId: eventId.toString() ?? '',
    }).then(() => {
      reloadAttendance();
      setOpen(false);
    });
  };

  const handleleaveJoinWaitingList = () => {
    leaveJoinWaitingList({
      appUserId: userId,
      eventId: eventId.toString() ?? '',
    }).then(() => {
      reloadAttendance();
      setOpen(false);
    });
  };

  const handleLeaveEvent = () => {
    registerLeaveAttendance({
      appUserId: userId,
      eventId: eventId.toString() ?? '',
    }).then(() => {
      reloadAttendance();
      setOpen(false);
    });
  };
  const handleJoinEvent = () => {
    registerJoinAttendance({
      appUserId: userId,
      eventId: eventId.toString() ?? '',
    }).then(() => {
      reloadAttendance();
      setOpen(false);
    });
  };

  const DeadlineReached = () => {
    const today = new Date();
    if (deadlineAttendance === null) {
      return false;
    }
    const deadline = new Date(deadlineAttendance);
    if (today > deadline) {
      return true;
    } else {
      return false;
    }
  };

  const AttendanceNotStarted = () => {
    const today = new Date();
    if (startAttendance === null) {
      return false;
    }
    const start = new Date(startAttendance);
    if (today < start) {
      return true;
    } else {
      return false;
    }
  };

  const maxAttendeesReached = () => {
    if (maxCapacity) {
      if (members.length >= maxCapacity) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {maxAttendeesReached() && !isAttending ? (
        <Box>
          {!isWaiting ? (
            <Button
              variant={'contained'}
              disableElevation
              disabled={
                (!isAttending && DeadlineReached()) || AttendanceNotStarted()
              }
              onClick={handleregisterJoinWaitingList}
              sx={{
                backgroundColor: 'primary',
                width: isCard ? '50%' : '100%',
                whiteSpace: isCard ? '' : 'nowrap',
                float: isCard ? 'right' : '',
              }}
            >
              Join Waitinglist
            </Button>
          ) : (
            <Button
              variant={'outlined'}
              disableElevation
              onClick={handleleaveJoinWaitingList}
              sx={{
                backgroundColor: 'primary',
                width: isCard ? '50%' : '100%',
                whiteSpace: isCard ? '' : 'nowrap',
                float: isCard ? 'right' : '',
              }}
            >
              Leave Waitinglist
            </Button>
          )}
        </Box>
      ) : (
        <Button
          variant={isAttending ? 'outlined' : 'contained'}
          disableElevation
          onClick={() => setOpen(true)}
          disabled={
            (!isAttending && DeadlineReached()) || AttendanceNotStarted()
          }
          sx={{
            backgroundColor: 'primary',
            width: isCard ? '50%' : '100%',
            whiteSpace: isCard ? '' : 'nowrap',
            float: isCard ? 'right' : '',
          }}
        >
          {isAttending ? 'Leave' : 'Attend'}
        </Button>
      )}

      <ConfirmationBoxNew
        open={open}
        title={
          isAttending
            ? 'Are you sure you want to unattend?'
            : 'Are you sure you want to attend?'
        }
        content={
          isAttending
            ? 'You can reattend if you change your mind'
            : 'We look forward to seeing you there'
        }
        handleClose={() => {
          setOpen(false);
        }}
        handleResponse={(response) => {
          if (response) {
            isAttending ? handleLeaveEvent() : handleJoinEvent();
          }
        }}
      />

      <AlertBox
        open={openError}
        text={'An error occured'}
        handleClose={() => {
          setOpenError(false);
        }}
      ></AlertBox>
    </>
  );
}

export default JoinEventButton;
