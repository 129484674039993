import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from 'api/axiosInstance';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { currentUserState } from 'atoms';

const useLoadUser = () => {
  const {
    isLoading: isLoadingAuth0,
    user: userAuth0,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [, setCurrentUser] = useRecoilState(currentUserState);

  const loadCurrentUser = async () => {
    if (isLoadingAuth0) return;
    if (!isAuthenticated) {
      setCurrentUser((prev) => ({ ...prev, isLoading: false }));
      return;
    }

    const accessToken = await getAccessTokenSilently();
    await axiosInstance
      .get<any>('/users/me', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        let data = res.data;
        let user = data.user;
        let membershipIDs = user.memberships.map((m: any) => m.socialGroupId);
        setCurrentUser((prev) => ({
          ...prev,
          ...data,
          ...user,
          userId: user.appUserId,
          location: user.location?.city.toLowerCase() ?? '',
          memberships: membershipIDs,
        }));
      })
      .catch((err) => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      });
    setCurrentUser((prev) => ({ ...prev, isLoading: false }));
  };

  function setAuth0Data() {
    if (!userAuth0) return;
    const roles: string[] = userAuth0['https://capsocial.com/roles'];
    setCurrentUser((prev) => {
      return {
        ...prev,
        isAuthenticated,
        isCapgemini: roles.some((r) => r === 'Cap'),
        isCoreMember: roles.some((r) => r === 'CoreMember'),
        isAdmin: roles.some((r) => r === 'Admin'),
        isCoreMemberOrAdmin: roles.some(
          (r) => r === 'Admin' || r === 'CoreMember'
        ),
      };
    });
  }

  useEffect(() => {
    setAuth0Data();
    loadCurrentUser();
  }, [isLoadingAuth0, isAuthenticated]);

  const reloadUser = () => {
    setAuth0Data();
    loadCurrentUser();
  };

  return {
    isAuthenticated,
    isLoadingAuth0,
    reloadUser,
  };
};

export default useLoadUser;
