import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography } from '@mui/material';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import InformationBanner from 'components/InformationBanner';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { currentLocation } from 'atoms';
import { themeColorState } from 'atoms/themeState';
import '../Home/index.scss';
import GroupCardAlignment from './GroupCardAlignment';

function LocationPage() {
  const { city } = useParams();
  const [, setThemeColor] = useRecoilState(themeColorState);
  const { data } = useHttpGet<any[]>('/locations/design');
  const [, setCurLocation] = useRecoilState(currentLocation);

  const { data: events, isLoading: loadingEvents } = useHttpGet<any[]>(
    `/locations/${city}/upcoming-events/published`
  );

  const navigate = useNavigate();
  const ViewEventsButton = () => (
    <Button
      disableElevation
      endIcon={<ArrowForwardIcon />}
      style={{ width: 'fit-content' }}
      sx={{ color: 'primary.main', margin: 'auto' }}
      onClick={() => navigate(`/events?city=${city}`)}
    >
      <Typography fontWeight="bold">View all activities</Typography>
    </Button>
  );

  useEffect(() => {
    if (city && data) {
      const location = data?.find(
        (location: any) => location.city.toLowerCase() === city.toLowerCase()
      );

      if (!location) return navigate('/');

      setThemeColor(location.color);
      localStorage.setItem('theme', location.color);
      setCurLocation(city.toLowerCase());
    }
  }, [city, data]);

  return (
    <Grid container gap={isMobile ? 6 : 12} direction="column">
      {!isMobile && (
        <img alt={'Alt'} src={'/blue_line_large.svg'} className="blue-line" />
      )}
      <InformationBanner />
      <Grid
        container
        direction="column"
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <EventsOrGroupsPreview
          title={'Hey! Here are some upcoming activities'}
          data={events || []}
          type="activities"
          disableShowMore={true}
        />
        {ViewEventsButton()}
      </Grid>
      <GroupCardAlignment />
    </Grid>
  );
}

export default LocationPage;
