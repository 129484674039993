import { useAuth0 } from '@auth0/auth0-react';
import { Button, Divider, Grid } from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import LoadingIcon from 'components/LoadingIcon';
import { CompressProfilePicture } from 'helpers/CompressImage';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

export interface UserForm {
  name: string;
  city: string;
  file?: Blob;
}

function MyAttendances() {
  const { userId } = useUser();

  const { data: attendingEvents } = useHttpGet<any[]>(`users/${userId}/events`);
  const { data: createdEvents } = useHttpGet<any[]>(
    `users/${userId}/created-events`
  );

  const { data: socialGroups } = useHttpGet<any[]>(
    `users/${userId}/social-groups`
  );
  const { data: leadGroups } = useHttpGet<number[]>(
    `/memberships/${userId}/lead-groups`
  );

  // Ignore the groups that the person is administrating, can be found in /manage
  const adminGroupFilter = (group: any) => {
    return group?.filter((g: any) => leadGroups?.includes(g.socialGroupId));
  };
  const memberGroupFilter = (group: any) => {
    return group?.filter(
      (g: any) => !leadGroups?.includes(g.socialGroupId) && g.isPublished
    );
  };

  return (
    <>
      <Grid
        minWidth={350}
        container
        direction={'column'}
        gap={2}
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <EventsOrGroupsPreview
          title="My upcoming activities"
          data={attendingEvents ?? []}
          type="activities"
          disableShowMore={false}
        />

        <Divider />
        <EventsOrGroupsPreview
          title="Hosted activities"
          data={createdEvents ?? []}
          type="activities"
          disableShowMore={false}
        />
        <Divider />
        <EventsOrGroupsPreview
          title="My groups"
          data={memberGroupFilter(socialGroups) ?? []}
          type="groups"
          disableShowMore={false}
        />
        <Divider />
        <EventsOrGroupsPreview
          title="Administrating groups"
          data={adminGroupFilter(socialGroups) ?? []}
          type="groups"
          disableShowMore={false}
        />
      </Grid>
    </>
  );
}

export default MyAttendances;
