import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CircularProgress, FormControl, Grid, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { LocationBase } from 'types';

export default function SideLocationMenu(props: {
  onClose?: () => void;
  isCurrentPath: boolean;
}) {
  const navigate = useNavigate();
  const { onClose } = props;
  const [open, setOpen] = useState(false);

  const { data: cities, isLoading } = useHttpGet<LocationBase[]>('/locations');

  return (
    <>
      <Button
        disableElevation
        style={{ justifyContent: 'flex-start', color: 'white' }}
        startIcon={<LocationOnIcon htmlColor="white" />}
        fullWidth
        endIcon={
          open ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />
        }
        onClick={() => setOpen((prev) => !prev)}
      >
        <Typography variant="h5" color="white">
          Location
        </Typography>
      </Button>
      {isLoading && (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {!isLoading && (
        <Collapse in={open} orientation="vertical" timeout="auto" unmountOnExit>
          <FormControl
            variant="filled"
            sx={{ minWidth: 120, marginLeft: '1.3em' }}
          >
            {cities &&
              cities.map((city: LocationBase) => (
                <MenuItem
                  sx={{
                    color: 'white',
                    pl: 4,
                  }}
                  key={city.city}
                  onClick={() => {
                    navigate(`/locations/${city.city.toLowerCase()}`);
                    onClose?.();
                  }}
                >
                  {city.city}
                </MenuItem>
              ))}
          </FormControl>
        </Collapse>
      )}
    </>
  );
}
