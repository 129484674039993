import { apiBaseUrl } from './common';
import axios from 'axios';

export default axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
